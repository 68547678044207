import { observer } from 'mobx-react-lite'
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { useLocalObservable } from 'mobx-react'
import ModalHeader from 'react-bootstrap/ModalHeader'
import { runInAction } from 'mobx'
import React from 'react'
import { TeacherDto } from '../../models/dto/TeacherDto'
import { ResourceListView } from '../views/ResourceListView'
import { TEACHER_RESOURCE_NAME } from '../../models/resources/TeacherResource'
import { fullName } from '../../helpers/string-helpers'
import { QueryWhereClause } from '../../api/ApiClient'

type Props = {
  cycleId: number
  onExited: () => void
  onChoose: (teacher: TeacherDto, finish: (closeModal: boolean) => void) => void
  where?: QueryWhereClause[]
}

type State = {
  show: boolean
  loading: boolean
  submitting: boolean
}

export const ChooseTeacherModal = observer((props: Props) => {
  const state = useLocalObservable<State>(() => ({
    show: true,
    loading: true,
    submitting: false,
  }))

  const toggle = () => {
    state.show = false
  }

  const choose = async (teacher: TeacherDto) => {
    runInAction(() => state.submitting = true)

    props.onChoose(teacher, close => {
      runInAction(() => {
        state.submitting = false

        if (close) {
          state.show = false
        }
      })
    })
  }

  return <Modal show={state.show} onExited={props.onExited}>
    <ModalHeader>
      Choose Teacher
    </ModalHeader>
    <ModalBody>
      <ResourceListView
        resourceName={TEACHER_RESOURCE_NAME}
        columns={[
          {
            title: 'Name',
            sortable: true,
            sortKey: 'lastName',
            renderItem: (_, item) => fullName(item),
          },
          {
            accessor: 'externalId',
            title: 'External ID',
            sortable: true,
          },
          {
            renderItem: (_, item) => <Button size="sm" onClick={() => choose(item)}>Choose</Button>
          },
        ]}
        where={[
          { id: 'cycleId', value: props.cycleId },
          ...(props.where ?? []),
        ]}
      />
    </ModalBody>
    <ModalFooter>
      <Button variant="secondary" onClick={toggle} disabled={state.submitting}>Cancel</Button>
    </ModalFooter>
  </Modal>
})
