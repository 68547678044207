import moment from "moment-timezone"

export const MYSQL_DATE_FORMAT = 'YYYY-MM-DD'
export const MYSQL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const SHORT_DATE_FORMAT = 'MM/DD/YYYY'

export const utc = (str?: string) => {
  return str ? moment.tz(str, 'UTC') : moment.tz('UTC')
}
