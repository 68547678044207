import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { Alert, ProgressBar } from 'react-bootstrap'
import { css, StyleSheet } from 'aphrodite'
import { useLocalObservable } from 'mobx-react'
import { runInAction } from 'mobx'
import { extractErrorMessage } from '../common/util'
import { StudentDto } from '../models/dto/StudentDto'
import { fetchResource } from '../common/ResourceHelpers'
import { STUDENT_RESOURCE_NAME } from '../models/resources/StudentResource'
import { getAppState } from '../stores/AppStateStore'
import { fullName } from '../helpers/string-helpers'
import QRCode from 'react-qr-code'
import classNames from 'classnames'

type Params = {
  id: string
}

type State = {
  loading: boolean
  data?: {
    students: StudentDto[]
  }
  error?: string
}

export const QrCodeSheet = observer(() => {
  const params = useParams<Params>()

  const state = useLocalObservable<State>(() => ({
    loading: false,
    data: undefined,
    error: undefined,
  }))

  useLayoutEffect(() => {
    getAppState().eventBus.emit('enable-print-view')
    return () => {
      getAppState().eventBus.emit('disable-print-view')
    }
  }, [])

  const loadStudents = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      const students = await fetchResource<StudentDto>(STUDENT_RESOURCE_NAME, {
        where: [{
          cycleId: params.id,
        }],
        sortDescriptor: {
          sortKey: 'lastName',
        },
      })

      runInAction(() => {
        state.data = {
          students,
        }
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [params.id, state])

  useEffect(() => {
    loadStudents().then()
  }, [
    loadStudents,
  ])

  return state.error
    ? <Alert variant="danger">{state.error}</Alert>
    : (state.loading || !state.data)
      ? <ProgressBar animated now={100}/>
      : <div className={css(styles.container)}>
        <div className={css(styles.listContainer)}>
          {
            state.data.students.map((student, idx) => <div key={student.id} className={classNames(css(styles.listItem), idx > 0 && idx % 9 === 0 ? 'page-break' : undefined)}>
              <div className={css(styles.wrapper)}>
                <div className={css(styles.qrCode)}>
                  {
                    student.uniqueCode
                      ? <QRCode
                        value={student.uniqueCode}
                        size={200}
                      />
                      : null
                  }
                </div>
                <div className={css(styles.textCode)}>
                  {student.uniqueCode}
                </div>
                <div className={css(styles.name)}>
                  {fullName(student)}
                </div>
              </div>
            </div>)
          }
        </div>
      </div>
})

const styles = StyleSheet.create({
  container: {},
  listContainer: {
    position: 'relative',
  },
  listItem: {
    display: 'inline-block',
    width: '33%',
    marginBottom: 80,
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  qrCode: {
    width: 200,
    height: 200,
  },
  textCode: {
    textAlign: 'center',
    marginTop: 8,
  },
  name: {
    textAlign: 'center',
    marginTop: 16,
  }
})
