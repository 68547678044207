import { AxiosResponse } from 'axios'
import ErrorBag from './ErrorBag'
import { getModalManager } from '../contexts/ModalContext'

export function transformIf<T = any> (v: T, t: (a: T) => T | undefined) {
  return v ? t(v) : v
}

export function actionIf<T = any> (v: T | undefined, t: (a: T) => void) {
  v && t(v)
}

type HandleErrorResponseOptions = {
  suppressDefaultAlert?: boolean
}

export const handleErrorResponse = (response: AxiosResponse, errorBag?: ErrorBag, options: HandleErrorResponseOptions = {}) => {
  if (errorBag) {
    if (response && response.status === 422) {
      if (response.data && response.data.errors) {
        errorBag.addErrors(response.data.errors)
        return
      }
    }
  }

  if (!options.suppressDefaultAlert) {
    getModalManager().showModal({
      title: 'Error',
      message: extractErrorMessage(response)
    })
  }
}

export const extractErrorMessage = (response: AxiosResponse) => {
  return ((response && response.data)
    ? (response.data.error || response.data.message)
    : undefined)
    || 'A server error has occurred'
}

export function formatNumber (c: number, decPlaces: number = 0, thouSeparator: string | undefined = undefined, decSeparator: string | undefined = undefined, forceDecimal: boolean = false): string {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces
  decSeparator = decSeparator === undefined ? '.' : decSeparator
  thouSeparator = thouSeparator === undefined ? ',' : thouSeparator
  let n: number = c
  let sign = n < 0 ? '-' : ''
  let s = Math.abs(+n || 0)
    .toFixed(decPlaces)
  n = Number(s)
  let i = parseInt(s) + ''
  let j = (i.length > 3) ? (i.length % 3) : 0

  let str = sign + (j ? i.substr(0, j) + thouSeparator : '') + i.substr(j)
    .replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator)
  if (n % 1 !== 0 || forceDecimal) {
    return str + (decPlaces ? decSeparator + Math.abs(n - Number(i))
      .toFixed(decPlaces)
      .slice(2) : '')
  } else {
    return str
  }
}

type StudentDisplayName = {
  firstName: string
  lastName: string
  externalId: string | null
}

export function studentDisplayName (info: StudentDisplayName) {
  return `${info.firstName} ${info.lastName}${info.externalId ? ` #${info.externalId}` : ''}`
}

export async function fileToBase64 (file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
