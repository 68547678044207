import React, { SyntheticEvent, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useParams } from 'react-router'
import { Alert, Button, Form, ProgressBar } from 'react-bootstrap'
import { css, StyleSheet } from 'aphrodite'
import { useLocalObservable } from 'mobx-react'
import ApiClient from '../api/ApiClient'
import { runInAction, toJS } from 'mobx'
import { extractErrorMessage, handleErrorResponse } from '../common/util'
import ErrorBag from '../common/ErrorBag'
import { FormControl } from '../components/form/FormControl'
import { getAppState } from '../stores/AppStateStore'
import { Routes } from '../routes/AppRoutes'
import { route } from '../routes/routes'
import { getModalManager } from '../contexts/ModalContext'
import { StudentDto } from '../models/dto/StudentDto'

type Params = {
  cycleId: string
  id?: string
}

type State = {
  loading: boolean
  student?: StudentDto
  error?: string
  submitting: boolean
  formErrors: ErrorBag
  form: {
    firstName: string
    lastName: string
    dateOfBirth: string
    externalId: string
  }
}

export const StudentEdit = observer(() => {
  const params = useParams<Params>()
  const history = useHistory()

  const state = useLocalObservable<State>(() => ({
    loading: params.id !== undefined,
    error: undefined,
    formErrors: new ErrorBag(),
    submitting: false,
    form: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      externalId: '',
    },
  }))

  const loadStudent = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      const response = await ApiClient.getInstance().get<StudentDto>(`/admin/students/${params.id}`)

      runInAction(() => {
        state.student = response.data
        state.form = {
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          dateOfBirth: response.data.dateOfBirth,
          externalId: response.data.externalId ?? '',
        }
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [params.id, state])

  useEffect(() => {
    if (params.id) {
      loadStudent().then()
    }
  }, [
    params.id,
    loadStudent,
  ])

  const submit = async (ev: SyntheticEvent) => {
    ev.preventDefault()

    runInAction(() => state.submitting = true)

    state.formErrors.clearErrors()

    try {
      const formData: any = toJS(state.form)

      if (!params.id) {
        formData.cycleId = Number(params.cycleId)
      }

      const response = await ApiClient.getInstance().post(params.id ? `/admin/students/${params.id}` : '/admin/students', formData)

      getAppState().toast(params.id ? 'Student updated' : 'Student created')
      if (params.id) {
        history.push(route(Routes.students.show, { cycleId: params.cycleId, id: params.id }))
      } else {
        history.push(route(Routes.students.show, { cycleId: params.cycleId, id: response.data.id }))
      }
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      handleErrorResponse(err.response, state.formErrors)
    }

    runInAction(() => state.submitting = false)
  }

  const doDestroy = async () => {
    runInAction(() => state.submitting = true)

    try {
      await ApiClient.getInstance().delete(`/admin/students/${params.id}`)

      getAppState().toast('Student deleted')
      history.push(route(Routes.students.list, { cycleId: params.cycleId }))
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      handleErrorResponse(err.response, state.formErrors)
    }

    runInAction(() => state.submitting = false)
  }

  const destroy = async (ev: SyntheticEvent) => {
    ev.preventDefault()

    getModalManager().showModal({
      title: 'Confirm',
      message: 'Are you sure you want to delete this student?',
      buttons: [
        {
          text: 'Yes, delete',
          variant: "danger",
          onClick: (dismiss) => {
            dismiss()
            doDestroy().then()
          },
        },
        {
          text: 'Cancel',
          variant: "secondary",
          onClick: (dismiss) => {
            dismiss()
          },
        }
      ],
    })
  }

  return state.loading
    ? <ProgressBar animated now={100}/>
    : state.error
      ? <Alert variant="danger">{state.error}</Alert>
      : <div className={css(styles.container)}>
        <Form onSubmit={submit}>
          <FormControl
            field="firstName"
            label="First Name"
            errors={state.formErrors}>
            <Form.Control
              type="text"
              placeholder="Enter first name"
              value={state.form.firstName}
              onChange={ev => runInAction(() => state.form.firstName = ev.target.value)}
              disabled={state.submitting}
            />
          </FormControl>
          <FormControl
            field="lastName"
            label="Last Name"
            errors={state.formErrors}>
            <Form.Control
              type="text" placeholder="Enter last name"
              value={state.form.lastName}
              onChange={ev => runInAction(() => state.form.lastName = ev.target.value)}
              disabled={state.submitting}
            />
          </FormControl>
          <FormControl
            field="dateOfBirth"
            label="Date of Birth"
            errors={state.formErrors}>
            <Form.Control
              type="date"
              placeholder="Enter date of birth"
              value={state.form.dateOfBirth}
              onChange={ev => runInAction(() => state.form.dateOfBirth = ev.target.value)}
              disabled={state.submitting}
            />
          </FormControl>
          <FormControl
            field="externalId"
            label="External ID"
            errors={state.formErrors}>
            <Form.Control
              type="text" placeholder="Enter external ID"
              value={state.form.externalId}
              onChange={ev => runInAction(() => state.form.externalId = ev.target.value)}
              disabled={state.submitting}
            />
          </FormControl>
          <Button
            variant="secondary"
            type="button"
            disabled={state.submitting}
            onClick={() => history.goBack()}
            style={{ marginRight: 10 }}
          >Cancel</Button>
          {
            params.id
              ? <Button
                variant="danger"
                type="button"
                disabled={state.submitting}
                onClick={destroy}
                style={{ marginRight: 10 }}
              >Delete</Button>
              : null
          }
          <Button
            variant="primary"
            type="submit"
            disabled={state.submitting}
          >
            Save
          </Button>
        </Form>
      </div>
})

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
  },
})
