import React from 'react'
import { TableViewColumn } from '../components/table-view/TableView'
import { CrudResourceListView } from '../components/views/CrudResourceListView'
import { Link } from 'react-router-dom'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { DISTRICT_RESOURCE_NAME, DistrictResource } from '../models/resources/DistrictResource'

export const DistrictList = () => {
  const columns: TableViewColumn<DistrictResource>[] = [
    {
      accessor: 'name',
      title: 'Name',
      sortable: true,
      renderItem: (name, item) => <Link to={route(Routes.districts.edit, { id: item.id })}>{name}</Link>,
    },
    {
      accessor: 'location',
      title: 'Location',
      sortable: true,
    },
    {
      accessor: 'numberOfSchools',
      title: 'Schools',
      sortable: true,
    },
  ]

  return <div>
    <CrudResourceListView
      title="Districts"
      resourceName={DISTRICT_RESOURCE_NAME}
      columns={columns}
      createUrl={route(Routes.districts.create)}
      showSearch={true}
    />
  </div>
}
