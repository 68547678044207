type ConfigType = {
  API_URL: string

  TABLE_VIEW_PAGE_SIZE: number
}

export const Config: ConfigType = {
  API_URL: process.env.REACT_APP_API_URL!,

  TABLE_VIEW_PAGE_SIZE: 50,
}
