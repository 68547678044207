import { observer } from 'mobx-react-lite'
import { Button, Modal } from 'react-bootstrap'
import { getModalManager } from '../contexts/ModalContext'

export const ModalRenderer = observer(() => {
  const modalManager = getModalManager()

  return <>
    {
      modalManager.getModals().map(modal =>
        <Modal
          key={modal.uuid}
          show={modal.show}
          onHide={() => modalManager.dismissModal(modal.uuid)}
          onExited={() => modalManager.removeModal(modal.uuid)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{modal.options.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modal.options.message}</Modal.Body>
          <Modal.Footer>
            {
              modal.options.buttons
                ? modal.options.buttons.map((button, idx) => <Button key={idx} variant={button.variant} onClick={() => button.onClick(() => modalManager.dismissModal(modal.uuid))}>
                  {button.text}
                </Button>)
                : <Button variant="secondary" onClick={() => modalManager.dismissModal(modal.uuid)}>
                  Close
                </Button>
            }

          </Modal.Footer>
        </Modal>
      )
    }
  </>
})
