export const STUDENT_RESOURCE_NAME = 'students'

export type StudentResource = {
  id: number
  createdAt: string
  firstName: string
  lastName: string
  dateOfBirth: string
  uniqueCode: string
  externalId: string | null
}
