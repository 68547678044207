import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { AppRoutes, Routes } from './routes/AppRoutes'
import NotFoundView from './views/NotFoundView'
import { LoginView } from './views/LoginView'
import { route } from './routes/routes'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ModalRenderer } from './components/ModalRenderer'
import { getAppState } from './stores/AppStateStore'
import { observer } from 'mobx-react-lite'
import { css, StyleSheet } from 'aphrodite'
import { Button, Nav } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ScrollToTop } from './components/ScrollToTop'
import { SplashView } from './views/SplashView'
import { LoadingOverlay } from './components/LoadingOverlay'
import { useLocalObservable } from 'mobx-react'
import { runInAction } from 'mobx'

const App = observer(() => {
  const appState = getAppState()
  appState.restoreAuthToken()

  const isAuthenticated = appState.isAuthenticated

  return <>
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route
          path={Routes.login}
          exact={true}
          render={() => !isAuthenticated ? <LoginView/> : <Redirect to={{ pathname: route(Routes.index) }}/>}/>
        <Route
          path={Routes.index}
          render={props => isAuthenticated ? <AuthenticatedApp/> : <Redirect to={{ pathname: route(Routes.login), state: { from: props.location } }}/>}/>
      </Switch>
    </Router>
    <ModalRenderer/>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover={false}
    />
    <LoadingOverlay loading={appState.isModalSpinnerVisible}/>
  </>
})

const AuthenticatedApp = observer(() => {
  const appState = getAppState()
  const adminContext = appState.getAdminContext()

  const state = useLocalObservable(() => ({
    isPrintView: false,
  }))

  useEffect(() => {
    const listeners = [
      getAppState().eventBus.addRemovableListener('enable-print-view', () => {
        runInAction(() => state.isPrintView = true)
      }),
      getAppState().eventBus.addRemovableListener('disable-print-view', () => {
        runInAction(() => state.isPrintView = false)
      })
    ]

    return () => {
      listeners.forEach(l => l.remove())
    }
  })

  const renderRoutes = () => {
    return <Switch>
      {AppRoutes.map(r => {
        const { component, ...rest } = r
        return (
          <Route
            key={r.path}
            component={component}
            {...rest}
          />
        )
      })}
      <Route component={NotFoundView}/>
    </Switch>
  }

  return adminContext
    ? state.isPrintView
      ? renderRoutes()
      : <div className={css(styles.container)}>
        <div className={css(styles.header)}>
          <div className={css(styles.logoutButton)}>
            <Button
              variant="outline-light"
              onClick={() => getAppState().clearAuthToken()}
            >Log Out</Button>
          </div>
        </div>
        <Sidebar/>
        <div className={css(styles.mainContent)}>
          {renderRoutes()}
        </div>
      </div>
    : <SplashView/>
})

const Sidebar = observer(() => {
  const location = useLocation()

  const navLinks: {route: string, label: string}[] = [
    {
      route: Routes.districts.list,
      label: 'Districts',
    },
    {
      route: Routes.schools.list,
      label: 'Schools',
    },
  ]

  return <div className={css(styles.sidebar)}>
    <Nav className="flex-column" variant="pills">
      {
        navLinks.map((l, idx) => <Nav.Link key={idx} as={Link} to={route(l.route)} active={location.pathname === l.route}>{l.label}</Nav.Link>)
      }
    </Nav>
  </div>
})

export default App

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 60,
  },
  header: {
    position: 'absolute',
    width: '100%',
    height: 60,
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 20,
    backgroundColor: '#ff8d2d',
  },
  logoutButton: {},
  sidebar: {
    width: 300,
    minHeight: '100vh',
    backgroundColor: '#eee',
  },
  mainContent: {
    flex: 1,
    padding: 20,
  },
})
