import * as React from "react"
import { Button } from "react-bootstrap"
import { formatNumber } from "../../common/util"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

export class TableViewPagination extends React.Component<{
  numberOfPages: number,
  currentPage: number,
  setPage: (page: number) => void,
}> {
  render (): React.ReactNode {
    return <div className="table-view-pagination">
      <Button
        variant="primary"
        disabled={this.props.currentPage <= 1}
        onClick={() => this.props.setPage(this.props.currentPage - 1)}
      ><FontAwesomeIcon icon={faChevronLeft}/></Button>
      <div className="current-page-indicator">Page {formatNumber(this.props.currentPage)} of {formatNumber(this.props.numberOfPages)}</div>
      <Button
        color="primary"
        disabled={this.props.currentPage >= this.props.numberOfPages}
        onClick={() => this.props.setPage(this.props.currentPage + 1)}
      ><FontAwesomeIcon icon={faChevronRight}/></Button>
    </div>
  }
}
