import { compileRoutes } from "./routes"
import { IndexView } from '../views/IndexView'
import React from 'react'
import { DistrictList } from '../views/DistrictList'
import { DistrictEdit } from '../views/DistrictEdit'
import { SchoolList } from '../views/SchoolList'
import { SchoolEdit } from '../views/SchoolEdit'
import { SchoolShow } from '../views/SchoolShow'
import { CycleShow } from '../views/CycleShow'
import { TeacherList } from '../views/TeacherList'
import { TeacherEdit } from '../views/TeacherEdit'
import { TeacherShow } from '../views/TeacherShow'
import { SectionEdit } from '../views/SectionEdit'
import { SectionList } from '../views/SectionList'
import { SectionShow } from '../views/SectionShow'
import { StudentList } from '../views/StudentList'
import { StudentEdit } from '../views/StudentEdit'
import { StudentShow } from '../views/StudentShow'
import { QrCodeSheet } from '../views/QrCodeSheet'

const crudRoutes = (name: string) => ({
  _options: {
    prefix: `/${name}`,
  },

  list: '',
  create: '/new',
  show: '/show/:id(\\d+)',
  edit: '/:id(\\d+)',
})


const crudComponents = (options: {
  routes: any,
  list?: React.FC<any>,
  edit?: React.FC<any>,
  create?: React.FC<any>,
  show?: React.FC<any>,
}) => {
  const out: any[] = []

  if (options.routes.list && options.list) {
    out.push({
      path: options.routes.list,
      component: options.list,
    })
  }

  if (options.routes.edit && options.edit) {
    out.push({
      path: options.routes.edit,
      component: options.edit,
    })
  }

  if (options.routes.create && options.create) {
    out.push({
      path: options.routes.create,
      component: options.create,
    })
  }

  if (options.routes.show && options.show) {
    out.push({
      path: options.routes.show,
      component: options.show,
    })
  }

  return out
}

export const Routes = {
  index: '/',
  login: '/login',
  districts: crudRoutes('districts'),
  schools: crudRoutes('schools'),
  teachers: crudRoutes('cycles/:cycleId/teachers'),
  students: crudRoutes('cycles/:cycleId/students'),
  sections: crudRoutes('cycles/:cycleId/sections'),
  cycles: {
    _options: {
      prefix: `/cycles`,
    },
    show: '/show/:id(\\d+)',
    qrCodeSheet: '/qr-codes',
  },
}

compileRoutes(Routes)

export const AppRoutes = [
  {
    path: Routes.index,
    component: IndexView,
  },
  ...crudComponents({
    routes: Routes.districts,
    list: DistrictList,
    edit: DistrictEdit,
    create: DistrictEdit,
  }),
  ...crudComponents({
    routes: Routes.schools,
    list: SchoolList,
    edit: SchoolEdit,
    create: SchoolEdit,
    show: SchoolShow,
  }),
  {
    path: Routes.cycles.show,
    component: CycleShow,
  },
  {
    path: Routes.cycles.qrCodeSheet,
    component: QrCodeSheet,
  },
  ...crudComponents({
    routes: Routes.teachers,
    list: TeacherList,
    edit: TeacherEdit,
    create: TeacherEdit,
    show: TeacherShow,
  }),
  ...crudComponents({
    routes: Routes.students,
    list: StudentList,
    edit: StudentEdit,
    create: StudentEdit,
    show: StudentShow,
  }),  ...crudComponents({
    routes: Routes.sections,
    list: SectionList,
    edit: SectionEdit,
    create: SectionEdit,
    show: SectionShow,
  }),
].map(r => Object.assign({
  exact: true,
  sensitive: true,
  strict: true,
}, r))
