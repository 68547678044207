import _ from 'lodash'
import { css, StyleSheet } from 'aphrodite'

type Props = {
  items: any[]
}

export const Breadcrumbs = (props: Props) => {
  return <div className={css(styles.container)}>
    {
      _.flatMap(props.items, (i, idx) => [<div key={`sep-${idx}`} className={css(styles.separator)}/>, <div key={`item-${idx}`} className={css(styles.item)}>{i}</div>]).slice(1)
    }
  </div>
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  separator: {
    ':after': {
      content: '">"',
    },
    paddingLeft: 8,
    paddingRight: 8,
  },
  item: {}
})

