import ApiClient from '../api/ApiClient'
import { route } from '../routes/routes'
import { FetchParams } from '../components/table-view/TableViewAdapter'
import { LoadOptionsMethod } from '../components/form/AsyncSelect'
import { DISTRICT_RESOURCE_NAME, DistrictResource } from '../models/resources/DistrictResource'
import { SCHOOL_RESOURCE_NAME, SchoolResource } from '../models/resources/SchoolResource'

export const fetchResource = async <T> (resourceName: string, fetchParams?: FetchParams): Promise<T[]> => {
  const qs: any = {}

  if (fetchParams?.sortDescriptor) {
    qs.order = {
      col: fetchParams.sortDescriptor.sortKey,
      dir: fetchParams.sortDescriptor.descending ? 'desc' : 'asc',
    }
  }

  if (fetchParams?.where) {
    qs.where = fetchParams.where
  }

  if (fetchParams?.offset) {
    qs.offset = fetchParams.offset
  }

  if (fetchParams?.limit) {
    qs.limit = fetchParams.limit
  }

  const response = await ApiClient.getInstance()
    .get(route(`/admin/${resourceName}`, {}, qs))

  return response.data.records
}

export const loadDistrictOptions: LoadOptionsMethod = (inputValue: string, callback) => {
  fetchResource<DistrictResource>(DISTRICT_RESOURCE_NAME, {
    where: [
      { _scope: 'search', value: inputValue },
    ]
  }).then(districts => callback(districts.map(d => ({ value: String(d.id), label: d.name }))))
}

export const loadSchoolOptions: LoadOptionsMethod = (inputValue: string, callback) => {
  fetchResource<SchoolResource>(SCHOOL_RESOURCE_NAME, {
    where: [
      { _scope: 'search', value: inputValue },
    ]
  }).then(schools => callback(schools.map(d => ({ value: String(d.id), label: d.name }))))
}
