import { makeObservable, observable, runInAction } from 'mobx'
import { toast as toastify } from 'react-toastify'
import { AdminContext } from '../models/AdminContext'
import { EventBus } from '../common/EventBus'

const AUTH_TOKEN_STORAGE_KEY = 'authToken'

class AppStateStore {
  private authToken: string | undefined = undefined
  private adminContext: AdminContext | undefined = undefined
  private modalSpinnerCount: number = 0
  public readonly eventBus = new EventBus()

  constructor () {
    makeObservable<this, 'authToken' | 'adminContext' | 'modalSpinnerCount'>(this, {
      authToken: observable,
      adminContext: observable,
      modalSpinnerCount: observable,
    })
  }

  get isModalSpinnerVisible (): boolean {
    return this.modalSpinnerCount > 0
  }

  public showModalSpinner () {
    runInAction(() => this.modalSpinnerCount++)
  }

  public dismissModalSpinner () {
    runInAction(() => this.modalSpinnerCount > 0 && this.modalSpinnerCount--)
  }

  restoreAuthToken = () => {
    const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)
    if (authToken) {
      runInAction(() => this.authToken = authToken)
    }
  }

  setAuthToken = (authToken: string) => {
    runInAction(() => this.authToken = authToken)
    localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken)
  }

  clearAuthToken = () => {
    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY)
    runInAction(() => {
      this.authToken = undefined
      this.adminContext = undefined
    })
  }

  getAuthToken = () => {
    return this.authToken
  }

  get isAuthenticated () {
    return this.authToken !== undefined
  }

  setAdminContext = (adminContext: AdminContext) => {
    runInAction(() => this.adminContext = adminContext)
  }

  getAdminContext = () => this.adminContext

  clearAdminContext = () => {
    runInAction(() => this.adminContext = undefined)
  }

  toast = (content: string, type: 'success' = 'success') => {
    toastify[type](content, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    })
  }
}

const appState = new AppStateStore()

export const getAppState = () => appState
