import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { Alert, Button, Card, Col, ProgressBar, Row } from 'react-bootstrap'
import { css, StyleSheet } from 'aphrodite'
import { useLocalObservable } from 'mobx-react'
import ApiClient from '../api/ApiClient'
import { runInAction } from 'mobx'
import { extractErrorMessage } from '../common/util'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { SchoolDto } from '../models/dto/SchoolDto'
import { DistrictDto } from '../models/dto/DistrictDto'
import { CycleDto } from '../models/dto/CycleDto'
import { SHORT_DATE_FORMAT } from '../helpers/date-helpers'
import moment from 'moment-timezone'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { Link } from 'react-router-dom'

type Params = {
  id: string
}

type State = {
  loading: boolean
  data?: {
    district: DistrictDto
    school: SchoolDto
    cycle: CycleDto
    numberOfTeachers: number
    numberOfSections: number
    numberOfStudents: number
    numberOfParents: number
  }
  error?: string
}

export const CycleShow = observer(() => {
  const params = useParams<Params>()

  const state = useLocalObservable<State>(() => ({
    loading: false,
    data: undefined,
    error: undefined,
  }))

  const loadCycle = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      type Response = {
        cycle: CycleDto
        school: SchoolDto
        district: DistrictDto
        numberOfTeachers: number
        numberOfSections: number
        numberOfStudents: number
        numberOfParents: number
      }

      let response: Response = (await ApiClient.getInstance().get(`/admin/cycles/${params.id}`)).data

      runInAction(() => {
        state.data = {
          cycle: response.cycle,
          school: response.school,
          district: response.district,
          numberOfTeachers: response.numberOfTeachers,
          numberOfSections: response.numberOfSections,
          numberOfStudents: response.numberOfStudents,
          numberOfParents: response.numberOfParents,
        }
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [params.id, state])

  useEffect(() => {
    if (params.id) {
      loadCycle().then()
    }
  }, [
    params.id,
    loadCycle,
  ])

  return state.error
    ? <Alert variant="danger">{state.error}</Alert>
    : (state.loading || !state.data)
      ? <ProgressBar animated now={100}/>
      : <div className={css(styles.container)}>
        <div className={css(styles.header)}>
          <Breadcrumbs
            items={[
              <Link to={route(Routes.schools.list)}>Schools</Link>,
              <Link to={route(Routes.schools.show, { id: state.data.school.id })}>{state.data.school.name}</Link>,
              state.data.cycle.name,
            ]}
          />
        </div>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>
              Cycle Details
              <Button variant="primary" size="sm" className="ml-2" as={Link} target="_blank" to={route(Routes.cycles.qrCodeSheet, { id: params.id })}>Print QR Codes</Button>
            </Card.Title>
            <Row>
              <Col lg={4}>
                <b>School</b>
                <div>{state.data.school.name}</div>
              </Col>
              <Col lg={4}>
                <b>District</b>
                <div>{state.data.district.name}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <b>Cycle</b>
                <div>{state.data.cycle.name}</div>
              </Col>
              <Col lg={4}>
                <div>
                  {
                    (state.data.cycle.activatedAt && !state.data.cycle.archivedAt)
                      ? <b>Active</b>
                      : <b>Inactive</b>
                  }
                  {
                    state.data.cycle.activatedAt
                      ? <div>Started {moment(state.data.cycle.activatedAt).format(SHORT_DATE_FORMAT)}</div>
                      : null
                  }
                  {
                    state.data.cycle.archivedAt
                      ? <div>Ended {moment(state.data.cycle.activatedAt).format(SHORT_DATE_FORMAT)}</div>
                      : null
                  }
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div>
          <Link to={route(Routes.teachers.list, { cycleId: state.data.cycle.id })}>{state.data.numberOfTeachers} teachers</Link>
        </div>
        <div>
          <Link to={route(Routes.students.list, { cycleId: state.data.cycle.id })}>{state.data.numberOfStudents} students</Link>
        </div>
        <div>
          <Link to={route(Routes.sections.list, { cycleId: state.data.cycle.id })}>{state.data.numberOfSections} sections</Link>
        </div>
        <div>
          {state.data.numberOfParents} parents
        </div>
      </div>
})

const styles = StyleSheet.create({
  container: {},
  header: {
    marginBottom: 20,
  },
  cardRow: {
    marginBottom: 20,
  }
})
