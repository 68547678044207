import React from 'react'
import { TableViewColumn } from '../components/table-view/TableView'
import { CrudResourceListView } from '../components/views/CrudResourceListView'
import { Link } from 'react-router-dom'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { SCHOOL_RESOURCE_NAME, SchoolResource } from '../models/resources/SchoolResource'

export const SchoolList = () => {
  const columns: TableViewColumn<SchoolResource>[] = [
    {
      accessor: 'name',
      title: 'Name',
      sortable: true,
      renderItem: (name, item) => <Link to={route(Routes.schools.show, { id: item.id })}>{name}</Link>,
    },
    {
      accessor: 'districtName',
      title: 'District',
      sortable: true,
    },
  ]

  return <div>
    <CrudResourceListView
      title="Schools"
      resourceName={SCHOOL_RESOURCE_NAME}
      columns={columns}
      createUrl={route(Routes.schools.create)}
      showSearch={true}
    />
  </div>
}
