import React, { SyntheticEvent, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useParams } from 'react-router'
import { Alert, Button, Form, ProgressBar } from 'react-bootstrap'
import { css, StyleSheet } from 'aphrodite'
import { useLocalObservable } from 'mobx-react'
import ApiClient from '../api/ApiClient'
import { runInAction, toJS } from 'mobx'
import { extractErrorMessage, handleErrorResponse } from '../common/util'
import ErrorBag from '../common/ErrorBag'
import { FormControl } from '../components/form/FormControl'
import { getAppState } from '../stores/AppStateStore'
import { Routes } from '../routes/AppRoutes'
import { route } from '../routes/routes'
import { getModalManager } from '../contexts/ModalContext'
import { AsyncSelect } from '../components/form/AsyncSelect'
import { DistrictDto } from '../models/dto/DistrictDto'
import { SchoolDto } from '../models/dto/SchoolDto'
import { loadDistrictOptions } from '../common/ResourceHelpers'

type Params = {
  id?: string
}

type State = {
  loading: boolean
  school?: SchoolDto
  defaultDistrict?: DistrictDto
  error?: string
  submitting: boolean
  formErrors: ErrorBag
  form: {
    name: string
    districtId?: string
  }
}

export const SchoolEdit = observer(() => {
  const params = useParams<Params>()
  const history = useHistory()

  const state = useLocalObservable<State>(() => ({
    loading: params.id !== undefined,
    school: undefined,
    error: undefined,
    formErrors: new ErrorBag(),
    submitting: false,
    form: {
      name: '',
      districtId: '',
    },
  }))

  const loadSchool = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      let response = await ApiClient.getInstance().get(`/admin/schools/${params.id}`)
      const school: SchoolDto = response.data

      response = await ApiClient.getInstance().get(`/admin/districts/${school.districtId}`)
      const district: DistrictDto = response.data

      runInAction(() => {
        state.school = school
        state.defaultDistrict = district
        state.form = {
          name: school.name,
          districtId: String(school.districtId),
        }
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [params.id, state])

  useEffect(() => {
    if (params.id) {
      loadSchool().then()
    }
  }, [
    params.id,
    loadSchool,
  ])

  const submit = async (ev: SyntheticEvent) => {
    ev.preventDefault()

    runInAction(() => state.submitting = true)

    state.formErrors.clearErrors()

    try {
      const formData = toJS(state.form)

      await ApiClient.getInstance().post(params.id ? `/admin/schools/${params.id}` : '/admin/schools', formData)

      getAppState().toast(params.id ? 'School updated' : 'School created')
      history.push(route(Routes.schools.list))
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      handleErrorResponse(err.response, state.formErrors)
    }

    runInAction(() => state.submitting = false)
  }

  const doDestroy = async () => {
    runInAction(() => state.submitting = true)

    try {
      await ApiClient.getInstance().delete(`/admin/schools/${params.id}`)

      getAppState().toast('School deleted')
      history.push(route(Routes.schools.list))
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      handleErrorResponse(err.response, state.formErrors)
    }

    runInAction(() => state.submitting = false)
  }

  const destroy = async (ev: SyntheticEvent) => {
    ev.preventDefault()

    getModalManager().showModal({
      title: 'Confirm',
      message: 'Are you sure you want to delete this school?',
      buttons: [
        {
          text: 'Yes, delete',
          variant: "danger",
          onClick: (dismiss) => {
            dismiss()
            doDestroy().then()
          },
        },
        {
          text: 'Cancel',
          variant: "secondary",
          onClick: (dismiss) => {
            dismiss()
          },
        }
      ],
    })
  }

  return state.loading
    ? <ProgressBar animated now={100}/>
    : state.error
      ? <Alert variant="danger">{state.error}</Alert>
      : <div className={css(styles.container)}>
        <Form onSubmit={submit}>
          <FormControl
            field="name"
            label="School Name"
            errors={state.formErrors}>
            <Form.Control
              type="text"
              placeholder="Enter school name"
              value={state.form.name}
              onChange={ev => runInAction(() => state.form.name = ev.target.value)}
              disabled={state.submitting}
            />
          </FormControl>
          <FormControl
            field="districtId"
            label="District"
            errors={state.formErrors}>
            <AsyncSelect
              loadOptions={loadDistrictOptions}
              onChange={option => state.form.districtId = option?.value}
              isMulti={false}
              defaultValue={state.defaultDistrict ? { value: String(state.defaultDistrict.id), label: state.defaultDistrict.name } : undefined}
              isDisabled={state.submitting}
            />
          </FormControl>
          <Button
            variant="secondary"
            type="button"
            disabled={state.submitting}
            onClick={() => history.goBack()}
            style={{ marginRight: 10 }}
          >Cancel</Button>
          {
            params.id
              ? <Button
                variant="danger"
                type="button"
                disabled={state.submitting}
                onClick={destroy}
                style={{ marginRight: 10 }}
              >Delete</Button>
              : null
          }
          <Button
            variant="primary"
            type="submit"
            disabled={state.submitting}
          >
            Save
          </Button>
        </Form>
      </div>
})

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
  },
})
