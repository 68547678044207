import { ResourceListView } from './ResourceListView'
import { TableViewColumn } from '../table-view/TableView'
import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from 'react-router-bootstrap'
import { QueryWhereClause } from '../../api/ApiClient'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { EventBus } from '../../common/EventBus'

type Props = {
  title: string
  resourceName: string
  columns: TableViewColumn[]
  createUrl?: string
  where?: QueryWhereClause[]
  showSearch?: boolean
  actionButtons?: React.ReactElement[]
  tableViewCommandEventBus?: EventBus
}

export const CrudResourceListView = observer((props: Props) => {
  type State = {
    searchText: string
    where: QueryWhereClause[]
  }

  const state = useLocalObservable<State>(() => ({
    searchText: '',
    get where () {
      const where: QueryWhereClause[] = []

      if (this.searchText.trim()) {
        where.push({ _scope: 'search', value: this.searchText.trim() })
      }

      return where
    },
  }))

  return <div className={css(styles.container)}>
    <div className={css(styles.header)}>
      <div className={css(styles.title)}>
        <h1>{props.title}</h1>
      </div>
      <div className={css(styles.headerButtons)}>
        {
          props.actionButtons
            ? props.actionButtons.map((b, idx) => <div key={idx}>{b}</div>)
            : null
        }
        {
          props.createUrl
            ? <div>
              <LinkContainer to={props.createUrl}>
                <Button
                  variant="primary"
                >
                  <FontAwesomeIcon icon={faPlus}/> Add New
                </Button>
              </LinkContainer>
            </div>
            : null
        }
      </div>
    </div>
    {
      props.showSearch
        ? <div>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text><FontAwesomeIcon icon={faSearch}/></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Search"
              value={state.searchText}
              onChange={ev => runInAction(() => state.searchText = ev.target.value)}
            />
          </InputGroup>
        </div>
        : null
    }
    <ResourceListView
      tableViewCommandEventBus={props.tableViewCommandEventBus}
      resourceName={props.resourceName}
      columns={props.columns}
      where={[
        ...(props.where || []),
        ...state.where,
      ]}
    />
  </div>
})

const styles = StyleSheet.create({
  container: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    flex: 1
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'row',
    ':nth-child(1n) > div': {
      marginLeft: 8,
    },
    ':first-child': {
      marginLeft: 0,
    },
  },
})
