import Select from 'react-select/async'
import { observer, useLocalObservable } from 'mobx-react'
import { runInAction } from 'mobx'
import React from 'react'

export type SelectOption = {
  value: any
  label: string
}

export type LoadOptionsMethod = (inputValue: string, callback: LoadOptionsCallback) => void
export type LoadOptionsCallback = (options: SelectOption[]) => void
export type OnChangeMethod = (option: SelectOption) => void

type Props = {
  loadOptions: LoadOptionsMethod
  onChange: OnChangeMethod
  className?: string
  classNamePrefix?: string
  defaultValue?: SelectOption | SelectOption[]
  minimumSearchCharacters?: number
  isMulti: boolean
  isDisabled: boolean
}

export const AsyncSelect = observer((props: Props) => {
  const state = useLocalObservable(() => ({
    currentInputValue: '',
    get showMinimumSearch() {
      return !!(props.minimumSearchCharacters && props.minimumSearchCharacters > 0 && state.currentInputValue.length < props.minimumSearchCharacters)
    },
  }))

  const onInputChange = (newValue: string) => {
    runInAction(() => state.currentInputValue = newValue)
  }

  const loadOptions = (inputValue: string, callback: LoadOptionsCallback) => {
    if (state.showMinimumSearch) {
      callback([])
    } else {
      props.loadOptions(inputValue, callback)
    }
  }

  const noOptionsMessage = () => <div className="minimum-search-characters-message">{`Type at least ${props.minimumSearchCharacters} characters...`}</div>

  return <Select
    isMulti={props.isMulti}
    cacheOptions
    defaultOptions
    defaultValue={props.defaultValue}
    loadOptions={loadOptions}
    // @ts-ignore
    onChange={props.onChange}
    className={props.className}
    classNamePrefix={props.classNamePrefix}
    components={state.showMinimumSearch ? { NoOptionsMessage: noOptionsMessage } : {}}
    onInputChange={onInputChange}
    isClearable={true}
    escapeClearsValue={true}
    isDisabled={props.isDisabled}
  />
})
