import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction, toJS } from 'mobx'
import { API } from '../api/API'
import { handleErrorResponse } from '../common/util'
import ErrorBag from '../common/ErrorBag'
import { FieldError } from '../components/FieldError'
import { ErrorList } from '../components/ErrorList'
import { getAppState } from '../stores/AppStateStore'
import { css, StyleSheet } from 'aphrodite'

export const LoginView = observer(() => {
  const state = useLocalObservable(() => ({
    form: {
      username: '',
      password: '',
    },
    submitting: false,
    formErrors: new ErrorBag(),
  }))

  const submit = async (ev: React.FormEvent) => {
    ev.preventDefault()

    runInAction(() => {
      state.submitting = true
      state.formErrors.clearErrors()
    })

    const formData = toJS(state.form)

    try {
      const response = await API.authenticate(formData)
      runInAction(() => getAppState().setAuthToken(response.authToken))
    } catch (err) {
      handleErrorResponse(err.response, state.formErrors)
    }

    runInAction(() => state.submitting = false)
  }

  return <div className={css(styles.container)}>
    <Form onSubmit={submit}>
      <ErrorList
        errors={state.formErrors.getUnboundErrors([
          'username',
          'password',
        ])}
      />
      <Form.Group controlId="loginUsername">
        <Form.Label srOnly>Username</Form.Label>
        <Form.Control
          disabled={state.submitting}
          type="text"
          placeholder="Username"
          autoFocus
          value={state.form.username}
          onChange={ev => runInAction(() => state.form.username = ev.target.value)}
        />
        <FieldError errors={state.formErrors} field="username"/>
      </Form.Group>
      <Form.Group controlId="loginPassword">
        <Form.Label srOnly>Password</Form.Label>
        <Form.Control
          disabled={state.submitting}
          type="password"
          placeholder="Password"
          value={state.form.password}
          onChange={ev => runInAction(() => state.form.password = ev.target.value)}
        />
        <FieldError errors={state.formErrors} field="password"/>
      </Form.Group>
      <Button variant="primary" type="submit" block>Log In</Button>
    </Form>
  </div>
})

const styles = StyleSheet.create({
  container: {
    maxWidth: 400,
    margin: '40px auto 0',
    backgroundColor: '#eee',
    padding: 40,
    borderRadius: 12,
  },
})
