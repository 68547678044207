import ApiClient from './ApiClient'

type AuthenticateRequest = {
  username: string
  password: string
}

type AuthenticateResponse = {
  id: number
  username: string
  authToken: string
}

export const authenticate = async (request: AuthenticateRequest): Promise<AuthenticateResponse> => {
  return (await ApiClient.getInstance().post('/public/auth/admin', request)).data
}
