import ErrorBag from '../../common/ErrorBag'
import { Form } from 'react-bootstrap'
import { FieldError } from '../FieldError'
import React from 'react'

type Props = {
  field: string
  label: string
  children: React.ReactNode
  errors: ErrorBag
}

export const FormControl = (props: Props) => {
  return <Form.Group controlId={props.field}>
    <Form.Label>{props.label}</Form.Label>
    {props.children}
    <FieldError errors={props.errors} field={props.field}/>
  </Form.Group>
}
