export const TEACHER_RESOURCE_NAME = 'teachers'

export type TeacherResource = {
  id: number
  createdAt: string
  firstName: string
  lastName: string
  emailAddress: string
  externalId: string | null
}
