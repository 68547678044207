import { observer } from "mobx-react"
import * as React from "react"
import { makeObservable, observable, runInAction, toJS } from "mobx"
import classNames from "classnames"
import * as _ from "lodash"
import { formatNumber } from "../../common/util"
import { TableViewAdapter } from "./TableViewAdapter"
import { QueryWhereClause } from '../../api/ApiClient'
import './TableView.scss'
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EventBus } from '../../common/EventBus'

export type TableViewColumn<T = any> = {
  title?: string
  accessor?: keyof T
  sortKey?: string
  renderTitle?: (column: TableViewColumn<T>) => React.ReactNode
  renderItem?: (value: any, item: T, column: TableViewColumn<T>) => React.ReactNode
  sortable?: boolean
  headerClassName?: string
  itemClassName?: string
}

export type SortDescriptor = {
  sortKey: string
  descending?: boolean
}

export enum TableViewEvents {
  REFRESH = 'refresh',
}

type TableViewProps = {
  adapter: TableViewAdapter
  columns: TableViewColumn[]
  pagination?: {offset: number, limit: number}
  filters?: {}
  hideRowNumbers?: boolean
  hideItemCount?: boolean
  defaultSort?: SortDescriptor
  tableClassName?: string
  onSortChanged?: (sortDescriptor: SortDescriptor | undefined) => void
  where: QueryWhereClause[]
  eventBus?: EventBus
}

export default observer(class TableView extends React.Component<TableViewProps> {
  static defaultProps = {
    where: [],
  }

  private error?: string = undefined
  private items: any[] = []
  private sortedColumn?: SortDescriptor = this.props.defaultSort
  private loading = false
  private totalItems = 0
  private itemNumberOffset = 0

  constructor (props: TableViewProps) {
    super(props)

    makeObservable<this, 'error' | 'items' | 'sortedColumn' | 'loading' | 'totalItems' | 'itemNumberOffset'>(this, {
      error: observable,
      items: observable,
      sortedColumn: observable,
      loading: observable,
      totalItems: observable,
      itemNumberOffset: observable,
    })
  }

  buildFetchParams = () => {
    return {
      sortDescriptor: this.sortedColumn,
      offset: this.props.pagination ? this.props.pagination.offset : undefined,
      limit: this.props.pagination ? this.props.pagination.limit : undefined,
      filters: this.props.filters,
      where: this.props.where,
    }
  }

  fetchAll = (onData: (items: any[]) => void, onError: (error: string) => void, onFinished: () => void) => {
    const fetchParams = this.buildFetchParams()

    const PAGE_SIZE = 1000

    const fetch = (offset: number) => {
      fetchParams.offset = offset
      fetchParams.limit = PAGE_SIZE
      this.props.adapter.fetchData(
        fetchParams,
        (items, total) => {
          onData(items)

          if (offset + PAGE_SIZE < total) {
            fetch(offset + PAGE_SIZE)
          } else {
            onFinished()
          }
        }, error => {
          onError(error)
        }, () => {
        }
      )
    }

    fetch(0)
  }

  fetchData = () => {
    runInAction(() => {
      this.loading = true
      this.error = undefined
    })

    this.props.adapter.fetchData(
      this.buildFetchParams(),
      (items: any[], total: number) => {
        runInAction(() => {
          this.items = items
          this.totalItems = total
          this.itemNumberOffset = this.props.pagination ? this.props.pagination.offset : 0
        })
      }, (error: string) => {
        runInAction(() => {
          this.error = error
          this.items = []
        })
      }, () => {
        runInAction(() => {
          this.loading = false
        })
      }
    )
  }

  private renderHeaderColumn = (column: TableViewColumn) => {
    return column.renderTitle
      ? column.renderTitle(column)
      : column.title === undefined ? '' : column.title
  }

  private setSortColumn = (column: TableViewColumn) => {
    const sortKey = this.getSortKey(column)

    if (!sortKey) {
      runInAction(() => {
        this.sortedColumn = undefined
      })
    } else if (this.sortedColumn && this.sortedColumn.sortKey === sortKey) {
      runInAction(() => {
        this.sortedColumn && (this.sortedColumn.descending = !this.sortedColumn.descending)
      })
    } else {
      runInAction(() => {
        this.sortedColumn = { sortKey: String(sortKey), descending: false }
      })
    }

    if (this.props.onSortChanged) {
      this.props.onSortChanged(this.sortedColumn)
    }

    this.fetchData()
  }

  private getSortKey = (column: TableViewColumn) => {
    return column.sortKey || column.accessor
  }

  private getSortIcon = (column: TableViewColumn) => {
    const sortDirection = this.getSortDirection(column)

    let sortIcon = faSort

    if (sortDirection === 'asc') {
      sortIcon = faSortUp
    } else if (sortDirection === 'desc') {
      sortIcon = faSortDown
    }

    return column.sortable
      ? <div className="sort-icon"><FontAwesomeIcon icon={sortIcon}/></div>
      : null
  }

  private getSortDirection = (column: TableViewColumn): 'asc' | 'desc' | 'none' => {
    return (this.sortedColumn && this.sortedColumn.sortKey === this.getSortKey(column))
      ? this.sortedColumn.descending
        ? 'desc'
        : 'asc'
      : 'none'
  }

  private renderHeader = () => {
    return <thead>
    <tr>
      {
        this.props.hideRowNumbers
          ? null
          : <th/>
      }
      {
        this.props.columns.map((col, idx) =>
          <th
            key={idx}
            className={classNames(
              {
                'sortable-header': col.sortable,
              },
              `sort-dir-${this.getSortDirection(col)}`
            )}
            onClick={
              col.sortable
                ? () => this.setSortColumn(col)
                : undefined
            }
          >
            <div className={classNames(col.headerClassName, 'th-container')}>
              {this.getSortIcon(col)}
              {this.renderHeaderColumn(col)}
            </div>
          </th>
        )
      }
    </tr>
    </thead>
  }

  private renderColumn = (column: TableViewColumn, item: any) => {
    const value = column.accessor ? _.get(item, column.accessor) : undefined
    return column.renderItem ? column.renderItem(value, item, column) : value
  }

  private renderBody = () => {
    return <tbody>
    {
      this.items.map((item, idx) =>
        <tr key={idx}>
          {
            this.props.hideRowNumbers
              ? null
              : <td>{1 + idx + this.itemNumberOffset}</td>
          }
          {
            this.props.columns.map((column, idx) =>
              <td key={idx} className={column.itemClassName}>
                {this.renderColumn(column, item)}
              </td>
            )
          }
        </tr>
      )
    }
    </tbody>
  }

  private renderLoader = () => {
    return this.loading
      ? <div className="table-view-spinner-backdrop"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/></div>
      : null
  }

  private refreshListener?: {remove: () => void}

  componentDidMount (): void {
    this.fetchData()

    this.refreshListener = this.props.eventBus?.addRemovableListener(TableViewEvents.REFRESH, () => this.fetchData())
  }

  componentWillUnmount () {
    if (this.refreshListener) {
      this.refreshListener.remove()
      this.refreshListener = undefined
    }
  }

  componentDidUpdate (prevProps: Readonly<TableViewProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (
      !_.isEqual(toJS(this.props.pagination), toJS(prevProps.pagination))
      || !_.isEqual(toJS(this.props.filters), toJS(prevProps.filters))
      || prevProps.adapter !== this.props.adapter
      || !_.isEqual(toJS(this.props.defaultSort), toJS(prevProps.defaultSort))
      || !_.isEqual(toJS(this.props.where), toJS(prevProps.where))
    ) {
      if (!_.isEqual(toJS(this.props.defaultSort), toJS(prevProps.defaultSort))) {
        runInAction(() => {
          this.sortedColumn = this.props.defaultSort
        })
      }
      this.fetchData()
    }
  }

  render (): React.ReactNode {
    return <div className="table-view-wrapper">
      {
        (this.items.length && !this.props.hideItemCount)
          ? <div className="table-view-item-count">Showing {formatNumber((this.props.pagination?.offset ?? 0) + 1)} to {formatNumber((this.props.pagination?.offset ?? 0) + this.items.length)} of {formatNumber(this.totalItems)} records</div>
          : null
      }
      <table className={classNames('table', 'table-striped', 'table-view', this.props.tableClassName)}>
        {this.renderHeader()}
        {
          this.error
            ? null
            : this.renderBody()
        }
      </table>
      {this.renderLoader()}
      {
        this.error
          ? <div className="table-view-error">{this.error || 'There was an error loading the data'}</div>
          : null
      }
    </div>
  }
})
