import { FetchParams, TableViewAdapter } from './TableViewAdapter'
import ApiClient, { QueryWhereClause } from '../../api/ApiClient'
import { extractErrorMessage } from '../../common/util'
import { route } from '../../routes/routes'
import axios, { CancelTokenSource } from 'axios'
import _ from 'lodash'

export type ResourceTableViewAdapterFetchResults<T> = {
  records: T[]
  total: number
}

export type ResourceTableViewAdapterOptions<T> = {
  transformResponse?: (records: T[]) => T[]
  onFetched?: (results: ResourceTableViewAdapterFetchResults<T>) => void
  where?: () => QueryWhereClause[]
}

export class ResourceTableViewAdapter<T> implements TableViewAdapter {
  private fetchDataCancelToken?: CancelTokenSource

  constructor (private resourceName: string, private options?: ResourceTableViewAdapterOptions<T>) {
  }

  fetchData = _.debounce(this._fetchData, 200)

  _fetchData (fetchParams: FetchParams, callback: (items: any[], total: number) => void, error: (message: string) => void, finished: () => void): void {
    if (this.fetchDataCancelToken) {
      this.fetchDataCancelToken.cancel()
      this.fetchDataCancelToken = undefined
    }
    this.fetchDataCancelToken = axios.CancelToken.source();

    (async () => {
      let cancelled = false

      try {
        const qs: any = {}

        if (fetchParams.sortDescriptor) {
          qs.order = {
            col: fetchParams.sortDescriptor.sortKey,
            dir: fetchParams.sortDescriptor.descending ? 'desc' : 'asc',
          }
        }

        if (fetchParams.where) {
          qs.where = fetchParams.where
        }

        if (this.options?.where) {
          qs.where = {
            ...qs.where,
            ...this.options.where(),
          }
        }

        if (fetchParams.offset) {
          qs.offset = fetchParams.offset
        }

        if (fetchParams.limit) {
          qs.limit = fetchParams.limit
        }

        const response = await ApiClient.getInstance()
          .get(route(`/admin/${this.resourceName}`, {}, qs), {
            cancelToken: this.fetchDataCancelToken?.token,
          })

        let records = response.data.records

        if (this.options?.transformResponse) {
          records = this.options.transformResponse(records)
        }

        callback(records, response.data._meta.totalRecords)

        if (this.options?.onFetched) {
          this.options.onFetched({
            records,
            total: response.data._meta.totalRecords,
          })
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          if (!err.response) {
            console.log(err)
          }

          error(extractErrorMessage(err.response))
        } else {
          cancelled = true
        }
      }

      if (!cancelled) {
        finished()
      }
    })()
  }
}
