import ErrorBag from '../common/ErrorBag'
import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { observer } from 'mobx-react'

type Props = {
  errors: ErrorBag,
  field: string,
}
export const FieldError = observer((props: Props) => {
  return props.errors.hasErrors(props.field)
    ? <div className={css(styles.error)}>{props.errors.getErrors(props.field).join(', ')}</div>
    : null
})

const styles = StyleSheet.create({
  error: {
    color: '#f00',
  },
})
