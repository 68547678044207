import * as _ from 'lodash'
import { action, makeObservable, observable, toJS } from "mobx"

interface ErrorList {
  [field: string]: string[],
}

export default class ErrorBag {
  errors: ErrorList = {}

  constructor (private convertFieldsToCamelCase: boolean = true) {
    makeObservable(this, {
      errors: observable,
      clearErrors: action,
      addError: action,
      addErrors: action,
    })
  }

  getUnboundErrors = (boundFields: string[]): string[] => {
    return _.flatMap(_.omit(this.errors, boundFields), e => e)
  }

  hasErrors = (field?: string) => {
    return field ? this.errors[field] && this.errors[field].length : _.flatMap(this.errors).length
  }

  getError = (field: string) => {
    return this.hasErrors(field)
      ? this.errors[field].join(', ')
      : ''
  }

  getErrors = (field?: string): string[] => field ? (this.errors[field] || []) : _.flatMap(this.errors, e => e)

  clearErrors = (): ErrorBag => {
    this.errors = {}
    return this
  }

  addError = (field: string, message: string): ErrorBag => {
    field = field.split('.').map(s => this.convertFieldsToCamelCase ? _.camelCase(s) : s).join('.')

    this.errors = { ...this.errors, [field]: [...(this.errors[field] || []), message] }

    return this
  }

  addErrors = (errors: ErrorList): ErrorBag => {
    for (let field in errors) {
      if (_.isArray(errors[field])) {
        errors[field].forEach((v: string) => {
          this.addError(field, v)
        })
      }
    }

    return this
  }

  getErrorList = () => {
    return toJS(this.errors)
  }
}
