import { observer } from 'mobx-react-lite'
import { getAppState } from '../stores/AppStateStore'
import { useEffect } from 'react'
import ApiClient from '../api/ApiClient'
import { Spinner } from 'react-bootstrap'

export const SplashView = observer(() => {

  useEffect(() => {
    const appState = getAppState()

    const loadAdminContext = async () => {
      try {
        const response = await ApiClient.getInstance().get('/admin/auth/admin')
        appState.setAdminContext(response.data)
      } catch (err) {
        appState.clearAuthToken()
      }
    }

    loadAdminContext().then()
  }, [])

  return <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 40 }}>
    <Spinner animation="border"/>
  </div>
})
