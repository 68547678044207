import React from 'react'
import { css, StyleSheet } from 'aphrodite'

type Props = {
  errors: string[],
}
export const ErrorList = (props: Props) => {
  return props.errors.length
    ? <div className={css(styles.error)}>{props.errors.map((e, idx) => <div key={idx} className="error-list-item">{e}</div>)}</div>
    : null
}

const styles = StyleSheet.create({
  error: {
    color: '#f00',
  },
})
