import EventEmitter from 'eventemitter3'

export class EventBus extends EventEmitter {
  addRemovableListener (event: string | symbol, fn: (...args: any[]) => void, context?: any) {
    super.addListener(event, fn, context)

    return {
      remove: () => {
        super.removeListener(event, fn, context)
      }
    }
  }
}
