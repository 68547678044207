import React from 'react'
import { observer } from 'mobx-react-lite'
import { useLocalObservable } from 'mobx-react'
import { runInAction } from 'mobx'
import { ResourceTableViewAdapter, ResourceTableViewAdapterFetchResults } from '../table-view/ResourceTableViewAdapter'
import TableView, { SortDescriptor, TableViewColumn } from '../table-view/TableView'
import { TableViewPagination } from '../table-view/TableViewPagination'
import { Config } from '../../common/Config'
import { EventBus } from '../../common/EventBus'
import { QueryWhereClause } from '../../api/ApiClient'

type Props = {
  resourceName: string
  columns: TableViewColumn[]
  tableViewCommandEventBus?: EventBus
  where?: QueryWhereClause[]
  defaultSort?: SortDescriptor
}

export const ResourceListView = observer((props: Props) => {
  const state = useLocalObservable(() => ({
    currentPage: 1,
    numberOfPages: 1,
    adapter: new ResourceTableViewAdapter(props.resourceName, {
      onFetched: (result: ResourceTableViewAdapterFetchResults<any>) => {
        runInAction(() => {
          state.numberOfPages = Math.ceil(result.total / Config.TABLE_VIEW_PAGE_SIZE)
          if (state.currentPage < 1) {
            state.currentPage = 1
          }
          if (state.currentPage > state.numberOfPages) {
            state.currentPage = state.numberOfPages
          }
        })
      }
    }),
  }))

  const setPage = (page: number) => {
    runInAction(() => state.currentPage = page)
  }

  return <div>
    <TableView
      eventBus={props.tableViewCommandEventBus}
      hideRowNumbers={true}
      adapter={state.adapter}
      columns={props.columns}
      defaultSort={props.defaultSort}
      where={props.where}
      pagination={{
        offset: Math.max((state.currentPage - 1), 0) * Config.TABLE_VIEW_PAGE_SIZE,
        limit: Config.TABLE_VIEW_PAGE_SIZE,
      }}
    />
    {
      state.numberOfPages > 1
        ? <TableViewPagination numberOfPages={state.numberOfPages} currentPage={state.currentPage} setPage={page => setPage(page)}/>
        : null
    }
  </div>
})
