import axios from 'axios'
import { Config } from '../common/Config'
import { getAppState } from '../stores/AppStateStore'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'

export type QueryWhereClause = {id: string, value: any} | {[column: string]: any} | {id: string, op: string, value: any} | {_scope: string, value?: any} | undefined
export type QueryOrderClause = {id: string, desc?: boolean} | string

class ApiClient {
  getInstance = () => {
    let params = {
      maxContentLength: 10000,
      timeout: 30000,
    }

    const authToken = getAppState().getAuthToken()

    const headers: any = {}

    if (authToken) {
      headers['X-ADMIN-AUTH-TOKEN'] = authToken
    }

    return axios.create({
      ...params,
      baseURL: Config.API_URL,
      headers: headers,
    })
  }
}

export default new ApiClient()
