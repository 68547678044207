import * as React from "react"
import { css, StyleSheet } from 'aphrodite'
import { CircleLoader } from "react-spinners"

type Props = {
  loading: boolean
}

export const LoadingOverlay = (props: Props) => {
  return props.loading
    ? <div className={css(styles.overlay)}>
      <div className={css(styles.message)}>
        <div className={css(styles.spinner)}>
          <CircleLoader color="#12497d" size={30}/>
        </div>
        <div className={css(styles.text)}>
          <a className={css(styles.link)} href="" onClick={ev => ev.preventDefault()} ref={input => input && input.focus()} onBlur={ev => ev.currentTarget.focus()}>Please wait...</a>
        </div>
      </div>
    </div>
    : null
}

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 10000,
  },

  message: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: 'rgba(255,255,255.6)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: 8,
  },

  spinner: {
    width: 30,
    height: 30,
  },

  text: {
    marginLeft: 12,
  },

  link: {
    ':hover': {
      textDecoration: 'none',
    },
    cursor: 'default',
    color: '#666',
    outline: 'none',
  },
})
