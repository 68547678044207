import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { Alert, Button, Card, CardGroup, Col, ProgressBar, Row } from 'react-bootstrap'
import { css, StyleSheet } from 'aphrodite'
import { useLocalObservable } from 'mobx-react'
import ApiClient from '../api/ApiClient'
import { runInAction } from 'mobx'
import { extractErrorMessage } from '../common/util'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { SchoolDto } from '../models/dto/SchoolDto'
import { DistrictDto } from '../models/dto/DistrictDto'
import { CycleDto } from '../models/dto/CycleDto'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { SectionDto } from '../models/dto/SectionDto'
import { TeacherDto } from '../models/dto/TeacherDto'
import { fullName } from '../helpers/string-helpers'
import { ChooseTeacherModal } from '../components/modals/ChooseTeacherModal'
import { getAppState } from '../stores/AppStateStore'
import { getModalManager } from '../contexts/ModalContext'
import { StudentDto } from '../models/dto/StudentDto'
import { ChooseStudentModal } from '../components/modals/ChooseStudentModal'

type Params = {
  id: string
}

type State = {
  loading: boolean
  data?: {
    district: DistrictDto
    school: SchoolDto
    cycle: CycleDto
    section: SectionDto
  }
  error?: string
}

export const SectionShow = observer(() => {
  const params = useParams<Params>()

  const state = useLocalObservable<State>(() => ({
    loading: false,
    data: undefined,
    error: undefined,
  }))

  const loadSection = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      type Response = {
        cycle: CycleDto
        school: SchoolDto
        district: DistrictDto
        section: SectionDto
      }

      let response: Response = (await ApiClient.getInstance().get(`/admin/sections/${params.id}/show`)).data

      runInAction(() => {
        state.data = {
          cycle: response.cycle,
          school: response.school,
          district: response.district,
          section: response.section,
        }
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [params.id, state])

  useEffect(() => {
    if (params.id) {
      loadSection().then()
    }
  }, [
    params.id,
    loadSection,
  ])

  return state.error
    ? <Alert variant="danger">{state.error}</Alert>
    : (state.loading || !state.data)
      ? <ProgressBar animated now={100}/>
      : <div className={css(styles.container)}>
        <div className={css(styles.header)}>
          <Breadcrumbs
            items={[
              <Link to={route(Routes.schools.list)}>Schools</Link>,
              <Link to={route(Routes.schools.show, { id: state.data.school.id })}>{state.data.school.name}</Link>,
              <Link to={route(Routes.cycles.show, { id: state.data.cycle.id })}>{state.data.cycle.name}</Link>,
              <Link to={route(Routes.sections.list, { cycleId: state.data.cycle.id })}>Sections</Link>,
              state.data.section.name,
            ]}
          />
        </div>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>
              Section Details
              <Button variant="primary" size="sm" className="ml-2" as={Link} to={route(Routes.sections.edit, { cycleId: state.data.cycle.id, id: state.data.section.id })}>Edit</Button>
            </Card.Title>
            <Row>
              <Col lg={3}>
                <b>Section</b>
                <div>{state.data.section.name}</div>
              </Col>
              <Col lg={3}>
                <b>Grade Level</b>
                <div>{state.data.section.gradeLevel}</div>
              </Col>
              <Col lg={3}>
                <b>External ID</b>
                <div>{state.data.section.externalId ?? '---'}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <b>School</b>
                <div>{state.data.school.name}</div>
              </Col>
              <Col lg={3}>
                <b>District</b>
                <div>{state.data.district.name}</div>
              </Col>
              <Col lg={3}>
                <b>Cycle</b>
                <div>{state.data.cycle.name}</div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <CardGroup>
          <TeacherList cycleId={state.data.cycle.id} sectionId={state.data.section.id}/>
          <StudentList cycleId={state.data.cycle.id} sectionId={state.data.section.id}/>
        </CardGroup>
      </div>
})

type TeacherListProps = {
  cycleId: number
  sectionId: number
}

const TeacherList = observer((props: TeacherListProps) => {
  type State = {
    loading: boolean
    teachers: TeacherDto[]
    error?: string
    renderAddModal: boolean
  }

  const state = useLocalObservable<State>(() => ({
    loading: true,
    teachers: [],
    renderAddModal: false,
  }))

  const loadTeachers = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      type Response = {
        teachers: TeacherDto[]
      }

      let response: Response = (await ApiClient.getInstance().get(`/admin/sections/${props.sectionId}/teachers`)).data

      runInAction(() => {
        state.teachers = response.teachers
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [props.sectionId, state])

  useEffect(() => {
    loadTeachers().then()
  }, [
    loadTeachers,
  ])

  const addTeacher = () => {
    runInAction(() => state.renderAddModal = true)
  }

  const removeTeacher = async (teacher: TeacherDto) => {
    getModalManager().showModal({
      title: 'Confirm',
      message: 'Are you sure you want to remove this teacher from this section?',
      buttons: [
        {
          text: 'Yes, remove',
          onClick: dismiss => {
            dismiss();

            (async () => {
              getAppState().showModalSpinner()

              try {
                await ApiClient.getInstance().delete(`/admin/sections/${props.sectionId}/teachers/${teacher.id}`)
                loadTeachers().then()
              } catch (err) {
                getModalManager().showModal({
                  title: 'Error',
                  message: extractErrorMessage(err),
                })
              }

              getAppState().dismissModalSpinner()
            })().then()
          },
          variant: 'danger',
        },
        {
          text: 'Cancel',
          variant: "secondary",
          onClick: (dismiss) => {
            dismiss()
          },
        },
      ],
    })
  }

  const submitAddTeacher = async (teacher: TeacherDto, finish: (close: boolean) => void) => {
    getAppState().showModalSpinner()

    try {
      await ApiClient.getInstance().post(`/admin/sections/${props.sectionId}/teachers`, {
        teacherId: teacher.id,
      })

      loadTeachers().then()

      finish(true)
    } catch (err) {
      getModalManager().showModal({
        title: 'Error',
        message: extractErrorMessage(err),
      })

      finish(false)
    }

    getAppState().dismissModalSpinner()
  }

  return <Card>
    <Card.Body>
      <Card.Title>
        <div className={css(styles.cardHeader)}>
          <div className={css(styles.cardTitle)}>
            Teachers
          </div>
          <div>
            <Button onClick={addTeacher}>Add Teacher</Button>
          </div>
        </div>
      </Card.Title>
      <table className="table table-striped">
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {
          state.teachers.map(teacher => <tr key={teacher.id}>
            <td>
              {teacher.externalId}
            </td>
            <td>
              {fullName(teacher)}
            </td>
            <td>
              <Button size="sm" variant="danger" onClick={() => removeTeacher(teacher)}>Remove</Button>
            </td>
          </tr>)
        }
        </tbody>
      </table>
      {
        state.renderAddModal
          ? <ChooseTeacherModal
            cycleId={props.cycleId}
            onExited={() => runInAction(() => state.renderAddModal = false)}
            onChoose={submitAddTeacher}
            where={[
              { _scope: 'notInSection', value: props.sectionId },
            ]}
          />
          : null
      }
    </Card.Body>
  </Card>
})

type StudentListProps = {
  cycleId: number
  sectionId: number
}

const StudentList = observer((props: StudentListProps) => {
  type State = {
    loading: boolean
    students: StudentDto[]
    error?: string
    renderAddModal: boolean
  }

  const state = useLocalObservable<State>(() => ({
    loading: true,
    students: [],
    renderAddModal: false,
  }))

  const loadStudents = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      type Response = {
        students: StudentDto[]
      }

      let response: Response = (await ApiClient.getInstance().get(`/admin/sections/${props.sectionId}/students`)).data

      runInAction(() => {
        state.students = response.students
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [props.sectionId, state])

  useEffect(() => {
    loadStudents().then()
  }, [
    loadStudents,
  ])

  const addStudent = () => {
    runInAction(() => state.renderAddModal = true)
  }

  const removeStudent = async (student: StudentDto) => {
    getModalManager().showModal({
      title: 'Confirm',
      message: 'Are you sure you want to remove this student from this section?',
      buttons: [
        {
          text: 'Yes, remove',
          onClick: dismiss => {
            dismiss();

            (async () => {
              getAppState().showModalSpinner()

              try {
                await ApiClient.getInstance().delete(`/admin/sections/${props.sectionId}/students/${student.id}`)
                loadStudents().then()
              } catch (err) {
                getModalManager().showModal({
                  title: 'Error',
                  message: extractErrorMessage(err),
                })
              }

              getAppState().dismissModalSpinner()
            })().then()
          },
          variant: 'danger',
        },
        {
          text: 'Cancel',
          variant: "secondary",
          onClick: (dismiss) => {
            dismiss()
          },
        },
      ],
    })
  }

  const submitAddStudent = async (student: StudentDto, finish: (close: boolean) => void) => {
    getAppState().showModalSpinner()

    try {
      await ApiClient.getInstance().post(`/admin/sections/${props.sectionId}/students`, {
        studentId: student.id,
      })

      loadStudents().then()

      finish(true)
    } catch (err) {
      getModalManager().showModal({
        title: 'Error',
        message: extractErrorMessage(err),
      })

      finish(false)
    }

    getAppState().dismissModalSpinner()
  }

  return <Card>
    <Card.Body>
      <Card.Title>
        <div className={css(styles.cardHeader)}>
          <div className={css(styles.cardTitle)}>
            Students
          </div>
          <div>
            <Button onClick={addStudent}>Add Student</Button>
          </div>
        </div>
      </Card.Title>
      <table className="table table-striped">
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {
          state.students.map(student => <tr key={student.id}>
            <td>
              {student.externalId}
            </td>
            <td>
              {fullName(student)}
            </td>
            <td>
              <Button size="sm" variant="danger" onClick={() => removeStudent(student)}>Remove</Button>
            </td>
          </tr>)
        }
        </tbody>
      </table>
      {
        state.renderAddModal
          ? <ChooseStudentModal
            cycleId={props.cycleId}
            onExited={() => runInAction(() => state.renderAddModal = false)}
            onChoose={submitAddStudent}
            where={[
              { _scope: 'notInSection', value: props.sectionId },
            ]}
          />
          : null
      }
    </Card.Body>
  </Card>
})

const styles = StyleSheet.create({
  container: {},
  header: {
    marginBottom: 20,
  },
  cardRow: {
    marginBottom: 20,
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardTitle: {
    flex: 1,
  },
})
