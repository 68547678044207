import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { Alert, Button, Card, Col, ProgressBar, Row } from 'react-bootstrap'
import { css, StyleSheet } from 'aphrodite'
import { useLocalObservable } from 'mobx-react'
import ApiClient from '../api/ApiClient'
import { runInAction } from 'mobx'
import { extractErrorMessage, handleErrorResponse } from '../common/util'
import { Link } from 'react-router-dom'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { SchoolDto } from '../models/dto/SchoolDto'
import { DistrictDto } from '../models/dto/DistrictDto'
import { CycleDto } from '../models/dto/CycleDto'
import { MYSQL_DATE_TIME_FORMAT, utc } from '../helpers/date-helpers'
import { EditCycleModal } from '../components/modals/EditCycleModal'
import _ from 'lodash'
import { getModalManager } from '../contexts/ModalContext'
import { getAppState } from '../stores/AppStateStore'
import { Breadcrumbs } from '../components/Breadcrumbs'

type Params = {
  id: string
}

type State = {
  school?: SchoolDto
  district?: DistrictDto
  cycles?: CycleDto[]
  loading: boolean
  error?: string
  renderCycleModal: boolean
  visibleCycles: CycleDto[]
}

export const SchoolShow = observer(() => {
  const params = useParams<Params>()

  const state = useLocalObservable<State>(() => ({
    loading: false,
    school: undefined,
    district: undefined,
    cycles: undefined as CycleDto[] | undefined,
    error: undefined,
    renderCycleModal: false,
    get visibleCycles () {
      return this.cycles
        ? _.orderBy(this.cycles, [c => c.activatedAt, c => c.id], ['desc', 'desc'])
        : []
    },
  }))

  const loadClass = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      let response = await ApiClient.getInstance().get(`/admin/schools/${params.id}`)
      const school: SchoolDto = response.data

      response = await ApiClient.getInstance().get(`/admin/districts/${school.districtId}`)
      const district: DistrictDto = response.data

      response = await ApiClient.getInstance().get(`/admin/schools/${params.id}/cycles`)
      const cycles: CycleDto[] = response.data.cycles

      runInAction(() => {
        state.school = school
        state.district = district
        state.cycles = cycles
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [params.id, state])

  const loadCycles = async () => {
    const response = await ApiClient.getInstance().get(`/admin/schools/${params.id}/cycles`)
    const cycles: CycleDto[] = response.data.cycles

    runInAction(() => {
      state.cycles = cycles
    })
  }

  useEffect(() => {
    if (params.id) {
      loadClass().then()
    }
  }, [
    params.id,
    loadClass,
  ])

  const activateCycle = (cycle: CycleDto) => {
    const activate = async () => {
      try {
        await ApiClient.getInstance().post(`/admin/schools/${params.id}/cycles/${cycle.id}/activate`)

        getAppState().toast('Cycle activated')

        loadCycles().then()
      } catch (err) {
        if (!err.response) {
          console.log(err)
        }

        handleErrorResponse(err.response)
      }
    }

    getModalManager().showModal({
      title: 'Confirm',
      message: 'Are you sure you want to activate this cycle?',
      buttons: [
        {
          text: 'Yes, activate',
          variant: "success",
          onClick: (dismiss) => {
            dismiss()

            activate().then()
          },
        },
        {
          text: 'Cancel',
          variant: "secondary",
          onClick: (dismiss) => {
            dismiss()
          },
        }
      ],
    })
  }

  const archiveCycle = async (cycle: CycleDto) => {
    const archive = async () => {
      try {
        await ApiClient.getInstance().post(`/admin/schools/${params.id}/cycles/${cycle.id}/archive`)

        getAppState().toast('Cycle archived')

        loadCycles().then()
      } catch (err) {
        if (!err.response) {
          console.log(err)
        }

        handleErrorResponse(err.response)
      }
    }

    getModalManager().showModal({
      title: 'Confirm',
      message: 'Are you sure you want to archive this cycle?',
      buttons: [
        {
          text: 'Yes, archive',
          variant: "success",
          onClick: (dismiss) => {
            dismiss()

            archive().then()
          },
        },
        {
          text: 'Cancel',
          variant: "secondary",
          onClick: (dismiss) => {
            dismiss()
          },
        }
      ],
    })
  }

  return (state.loading || !state.school || !state.district || !state.cycles)
    ? <ProgressBar animated now={100}/>
    : state.error
      ? <Alert variant="danger">{state.error}</Alert>
      : <div className={css(styles.container)}>
        <div className={css(styles.header)}>
          <Breadcrumbs
            items={[
              <Link to={route(Routes.schools.list)}>Schools</Link>,
              state.school.name,
            ]}
          />
        </div>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>
              School Details
              <Button variant="primary" size="sm" className="ml-2" as={Link} to={route(Routes.schools.edit, { id: state.school.id })}>Edit</Button>
            </Card.Title>
            <Row>
              <Col lg={4}>
                <b>School</b>
                <div>{state.school.name}</div>
              </Col>
              <Col lg={4}>
                <b>District</b>
                <div>{state.district.name}</div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {
          state.visibleCycles.length
            ? <div>
              <div className="text-right mb-2">
                <Button variant="primary" onClick={() => runInAction(() => state.renderCycleModal = true)}>Create Cycle</Button>
              </div>
              <table className="table table-striped">
                <thead>
                <tr>
                  <th>Cycle</th>
                  <th>Started</th>
                  <th>Ended</th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                {
                  state.visibleCycles.map(cycle => <tr key={cycle.id}>
                    <td><Link to={route(Routes.cycles.show, { id: cycle.id })}>{cycle.name}</Link></td>
                    <td>{cycle.activatedAt ? utc(cycle.activatedAt).format(MYSQL_DATE_TIME_FORMAT) : '---'}</td>
                    <td>{cycle.archivedAt ? utc(cycle.archivedAt).format(MYSQL_DATE_TIME_FORMAT) : '---'}</td>
                    <td>
                      {
                        !cycle.activatedAt
                          ? <Button variant="primary" size="sm" onClick={() => activateCycle(cycle)}>Activate</Button>
                          : null
                      }
                      {
                        (cycle.activatedAt && !cycle.archivedAt)
                          ? <Button variant="primary" size="sm" onClick={() => archiveCycle(cycle)}>Archive</Button>
                          : null
                      }
                    </td>
                  </tr>)
                }
                </tbody>
              </table>
            </div>
            : <Card>
              <Card.Body>
                <Row>
                  <Col className="text-center">
                    <p>No cycle has been configured for this school</p>
                    <Button variant="primary" onClick={() => runInAction(() => state.renderCycleModal = true)}>Create Cycle</Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
        }
        {
          state.renderCycleModal
            ? <EditCycleModal
              schoolId={state.school.id}
              onExited={() => runInAction(() => state.renderCycleModal = false)}
              onSaved={loadCycles}
            />
            : null
        }
      </div>
})

const styles = StyleSheet.create({
  container: {},
  header: {
    marginBottom: 20,
  },
  cardRow: {
    marginBottom: 20,
  }
})
