import { observer } from 'mobx-react-lite'
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { useLocalObservable } from 'mobx-react'
import ModalHeader from 'react-bootstrap/ModalHeader'
import { runInAction } from 'mobx'
import React from 'react'
import { ResourceListView } from '../views/ResourceListView'
import { QueryWhereClause } from '../../api/ApiClient'
import { SectionDto } from '../../models/dto/SectionDto'
import { SECTION_RESOURCE_NAME } from '../../models/resources/SectionResource'

type Props = {
  cycleId: number
  onExited: () => void
  onChoose: (section: SectionDto, finish: (closeModal: boolean) => void) => void
  where?: QueryWhereClause[]
}

type State = {
  show: boolean
  loading: boolean
  submitting: boolean
}

export const ChooseSectionModal = observer((props: Props) => {
  const state = useLocalObservable<State>(() => ({
    show: true,
    loading: true,
    submitting: false,
  }))

  const toggle = () => {
    state.show = false
  }

  const choose = async (section: SectionDto) => {
    runInAction(() => state.submitting = true)

    props.onChoose(section, close => {
      runInAction(() => {
        state.submitting = false

        if (close) {
          state.show = false
        }
      })
    })
  }

  return <Modal show={state.show} onExited={props.onExited}>
    <ModalHeader>
      Choose Section
    </ModalHeader>
    <ModalBody>
      <ResourceListView
        resourceName={SECTION_RESOURCE_NAME}
        columns={[
          {
            accessor: 'name',
            title: 'Name',
            sortable: true,
          },
          {
            accessor: 'gradeLevel',
            title: 'Grade Level',
            sortable: true,
          },
          {
            accessor: 'externalId',
            title: 'External ID',
            sortable: true,
          },
          {
            renderItem: (_, item) => <Button size="sm" onClick={() => choose(item)}>Choose</Button>
          },
        ]}
        where={[
          { id: 'cycleId', value: props.cycleId },
          ...(props.where ?? []),
        ]}
      />
    </ModalBody>
    <ModalFooter>
      <Button variant="secondary" onClick={toggle} disabled={state.submitting}>Cancel</Button>
    </ModalFooter>
  </Modal>
})
