import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { Alert, Button, Card, Col, ProgressBar, Row } from 'react-bootstrap'
import { css, StyleSheet } from 'aphrodite'
import { useLocalObservable } from 'mobx-react'
import ApiClient from '../api/ApiClient'
import { runInAction } from 'mobx'
import { extractErrorMessage } from '../common/util'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { SchoolDto } from '../models/dto/SchoolDto'
import { DistrictDto } from '../models/dto/DistrictDto'
import { CycleDto } from '../models/dto/CycleDto'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { TeacherDto } from '../models/dto/TeacherDto'
import { fullName } from '../helpers/string-helpers'
import { getModalManager } from '../contexts/ModalContext'
import { getAppState } from '../stores/AppStateStore'
import { SectionDto } from '../models/dto/SectionDto'
import { ChooseSectionModal } from '../components/modals/ChooseSectionModal'

type Params = {
  id: string
}

type State = {
  loading: boolean
  data?: {
    district: DistrictDto
    school: SchoolDto
    cycle: CycleDto
    teacher: TeacherDto
  }
  error?: string
}

export const TeacherShow = observer(() => {
  const params = useParams<Params>()

  const state = useLocalObservable<State>(() => ({
    loading: false,
    data: undefined,
    error: undefined,
  }))

  const loadTeacher = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      type Response = {
        cycle: CycleDto
        school: SchoolDto
        district: DistrictDto
        teacher: TeacherDto
      }

      let response: Response = (await ApiClient.getInstance().get(`/admin/teachers/${params.id}/show`)).data

      runInAction(() => {
        state.data = {
          cycle: response.cycle,
          school: response.school,
          district: response.district,
          teacher: response.teacher,
        }
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [params.id, state])

  useEffect(() => {
    if (params.id) {
      loadTeacher().then()
    }
  }, [
    params.id,
    loadTeacher,
  ])

  return state.error
    ? <Alert variant="danger">{state.error}</Alert>
    : (state.loading || !state.data)
      ? <ProgressBar animated now={100}/>
      : <div className={css(styles.container)}>
        <div className={css(styles.header)}>
          <Breadcrumbs
            items={[
              <Link to={route(Routes.schools.list)}>Schools</Link>,
              <Link to={route(Routes.schools.show, { id: state.data.school.id })}>{state.data.school.name}</Link>,
              <Link to={route(Routes.cycles.show, { id: state.data.cycle.id })}>{state.data.cycle.name}</Link>,
              <Link to={route(Routes.teachers.list, { cycleId: state.data.cycle.id })}>Teachers</Link>,
              fullName(state.data.teacher),
            ]}
          />
        </div>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>
              Teacher Details
              <Button variant="primary" size="sm" className="ml-2" as={Link} to={route(Routes.teachers.edit, { cycleId: state.data.cycle.id, id: state.data.teacher.id })}>Edit</Button>
            </Card.Title>
            <Row>
              <Col lg={3}>
                <b>Teacher</b>
                <div>{fullName(state.data.teacher)}</div>
              </Col>
              <Col lg={3}>
                <b>Email Address</b>
                <div><a href={`mailto:${state.data.teacher.emailAddress}`}>{state.data.teacher.emailAddress}</a></div>
              </Col>
              <Col lg={3}>
                <b>External ID</b>
                <div>{state.data.teacher.externalId ?? '---'}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <b>School</b>
                <div>{state.data.school.name}</div>
              </Col>
              <Col lg={3}>
                <b>District</b>
                <div>{state.data.district.name}</div>
              </Col>
              <Col lg={3}>
                <b>Cycle</b>
                <div>{state.data.cycle.name}</div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <SectionList
          cycleId={state.data.cycle.id}
          teacherId={state.data.teacher.id}
        />
      </div>
})

type SectionListProps = {
  cycleId: number
  teacherId: number
}

const SectionList = observer((props: SectionListProps) => {
  type State = {
    loading: boolean
    sections: SectionDto[]
    error?: string
    renderAddModal: boolean
  }

  const state = useLocalObservable<State>(() => ({
    loading: true,
    sections: [],
    renderAddModal: false,
  }))

  const loadSections = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      type Response = {
        sections: SectionDto[]
      }

      let response: Response = (await ApiClient.getInstance().get(`/admin/teachers/${props.teacherId}/sections`)).data

      runInAction(() => {
        state.sections = response.sections
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [props.teacherId, state])

  useEffect(() => {
    loadSections().then()
  }, [
    loadSections,
  ])

  const addSection = () => {
    runInAction(() => state.renderAddModal = true)
  }

  const removeSection = async (section: SectionDto) => {
    getModalManager().showModal({
      title: 'Confirm',
      message: 'Are you sure you want to remove this teacher from this section?',
      buttons: [
        {
          text: 'Yes, remove',
          onClick: dismiss => {
            dismiss();

            (async () => {
              getAppState().showModalSpinner()

              try {
                await ApiClient.getInstance().delete(`/admin/sections/${section.id}/teachers/${props.teacherId}`)
                loadSections().then()
              } catch (err) {
                getModalManager().showModal({
                  title: 'Error',
                  message: extractErrorMessage(err),
                })
              }

              getAppState().dismissModalSpinner()
            })().then()
          },
          variant: 'danger',
        },
        {
          text: 'Cancel',
          variant: "secondary",
          onClick: (dismiss) => {
            dismiss()
          },
        },
      ],
    })
  }

  const submitAddSection = async (section: SectionDto, finish: (close: boolean) => void) => {
    getAppState().showModalSpinner()

    try {
      await ApiClient.getInstance().post(`/admin/sections/${section.id}/teachers`, {
        teacherId: props.teacherId,
      })

      loadSections().then()

      finish(true)
    } catch (err) {
      getModalManager().showModal({
        title: 'Error',
        message: extractErrorMessage(err),
      })

      finish(false)
    }

    getAppState().dismissModalSpinner()
  }

  return <Card>
    <Card.Body>
      <Card.Title>
        <div className={css(styles.cardHeader)}>
          <div className={css(styles.cardTitle)}>
            Sections
          </div>
          <div>
            <Button onClick={addSection}>Add Section</Button>
          </div>
        </div>
      </Card.Title>
      <table className="table table-striped">
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Grade Level</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {
          state.sections.map(section => <tr key={section.id}>
            <td>
              {section.externalId}
            </td>
            <td>
              {section.name}
            </td>
            <td>
              {section.gradeLevel}
            </td>
            <td>
              <Button size="sm" variant="danger" onClick={() => removeSection(section)}>Remove</Button>
            </td>
          </tr>)
        }
        </tbody>
      </table>
      {
        state.renderAddModal
          ? <ChooseSectionModal
            cycleId={props.cycleId}
            onExited={() => runInAction(() => state.renderAddModal = false)}
            onChoose={submitAddSection}
            where={[
              { _scope: 'doesNotHaveTeacher', value: props.teacherId },
            ]}
          />
          : null
      }
    </Card.Body>
  </Card>
})

const styles = StyleSheet.create({
  container: {},
  header: {
    marginBottom: 20,
  },
  cardRow: {
    marginBottom: 20,
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardTitle: {
    flex: 1,
  },
})
