import { observer } from 'mobx-react-lite'
import { Button, Form, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { useLocalObservable } from 'mobx-react'
import ModalHeader from 'react-bootstrap/ModalHeader'
import { FormControl } from '../form/FormControl'
import { runInAction, toJS } from 'mobx'
import React, { SyntheticEvent } from 'react'
import ErrorBag from '../../common/ErrorBag'
import ApiClient from '../../api/ApiClient'
import { getAppState } from '../../stores/AppStateStore'
import { handleErrorResponse } from '../../common/util'

type Props = {
  schoolId: number
  cycleId?: number
  onExited: () => void
  onSaved?: () => void
}

type State = {
  show: boolean
  formErrors: ErrorBag
  form: {
    name: string
  }
  submitting: boolean
}

export const EditCycleModal = observer((props: Props) => {
  const state = useLocalObservable<State>(() => ({
    show: true,
    formErrors: new ErrorBag(),
    form: {
      name: '',
    },
    submitting: false,
  }))

  const toggle = () => {
    state.show = false
  }

  const submit = async (ev?: SyntheticEvent) => {
    ev?.preventDefault()

    runInAction(() => state.submitting = true)

    state.formErrors.clearErrors()

    try {
      const formData = toJS(state.form)

      await ApiClient.getInstance().post(props.cycleId ? `/admin/schools/${props.schoolId}/cycles/${props.cycleId}` : `/admin/schools/${props.schoolId}/cycles`, formData)

      getAppState().toast(props.cycleId ? 'Cycle updated' : 'Cycle created')
      props.onSaved && props.onSaved()
      toggle()
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      handleErrorResponse(err.response, state.formErrors)
    }

    runInAction(() => state.submitting = false)
  }

  return <Modal show={state.show} onExited={props.onExited}>
    <ModalHeader>
      {props.cycleId ? 'Edit Cycle' : 'Add Cycle'}
    </ModalHeader>
    <ModalBody>
      <Form onSubmit={submit}>
        <FormControl
          field="name"
          label="Cycle Name"
          errors={state.formErrors}>
          <Form.Control
            type="text"
            placeholder="Enter school name"
            value={state.form.name}
            onChange={ev => runInAction(() => state.form.name = ev.target.value)}
            disabled={state.submitting}
          />
        </FormControl>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button variant="secondary" onClick={toggle} disabled={state.submitting}>Cancel</Button>
      <Button variant="primary" disabled={state.submitting} onClick={submit}>{props.cycleId ? 'Save Changes' : 'Save'}</Button>
    </ModalFooter>
  </Modal>
})
