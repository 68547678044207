import { makeObservable, observable, runInAction } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import { actionIf } from '../common/util'
import { ButtonVariant } from 'react-bootstrap/types'

type ModalButton = {
  variant: ButtonVariant
  text: string
  onClick: (dismiss: () => void) => void
}

type ModalOptions = {
  title: string
  message: string
  buttons?: ModalButton[]
}

type Modal = {
  uuid: string
  show: boolean
  options: ModalOptions
}

class ModalManager {
  private modals: Modal[] = []

  constructor () {
    makeObservable<this, 'modals'>(this, {
      modals: observable,
    })
  }

  showModal = (options: ModalOptions) => {
    runInAction(() => {
      this.modals.push({
        uuid: uuidv4(),
        show: true,
        options,
      })
    })
  }

  getModals = (): Modal[] => {
    return this.modals.slice()
  }

  dismissModal = (uuid: string) => {
    actionIf(this.modals.find(a => a.uuid === uuid), a => runInAction(() => a.show = false))
  }

  removeModal = (uuid: string) => {
    runInAction(() => this.modals = this.modals.filter(a => a.uuid !== uuid))
  }
}

const modalManager = new ModalManager()

export const getModalManager = () => modalManager
