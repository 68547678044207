import React, { SyntheticEvent, useCallback, useEffect } from 'react'
import { TableViewColumn, TableViewEvents } from '../components/table-view/TableView'
import { CrudResourceListView } from '../components/views/CrudResourceListView'
import { Link } from 'react-router-dom'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { useParams } from 'react-router'
import { Alert, Button, Form, Modal, ProgressBar } from 'react-bootstrap'
import { useLocalObservable } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import ApiClient from '../api/ApiClient'
import { getModalManager } from '../contexts/ModalContext'
import { extractErrorMessage, fileToBase64 } from '../common/util'
import { getAppState } from '../stores/AppStateStore'
import { EventBus } from '../common/EventBus'
import { css, StyleSheet } from 'aphrodite'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { DistrictDto } from '../models/dto/DistrictDto'
import { SchoolDto } from '../models/dto/SchoolDto'
import { CycleDto } from '../models/dto/CycleDto'
import { fullName } from '../helpers/string-helpers'
import { STUDENT_RESOURCE_NAME, StudentResource } from '../models/resources/StudentResource'

type Params = {
  cycleId: string
}

type State = {
  loading: boolean
  data?: {
    district: DistrictDto
    school: SchoolDto
    cycle: CycleDto
  }
  error?: string

  renderImportModal: boolean
  eventBus: EventBus,
}

export const StudentList = observer(() => {
  const params = useParams<Params>()

  const state = useLocalObservable<State>(() => ({
    renderImportModal: false,
    eventBus: new EventBus(),
    loading: false,
    data: undefined,
  }))

  const showImportModal = () => {
    runInAction(() => state.renderImportModal = true)
  }

  const columns: TableViewColumn<StudentResource>[] = [
    {
      title: 'Name',
      sortable: true,
      sortKey: 'lastName',
      renderItem: (_, item) => <Link to={route(Routes.students.show, { cycleId: params.cycleId, id: item.id })}>{fullName(item)}</Link>,
    },
    {
      accessor: 'dateOfBirth',
      title: 'Date of Birth',
      sortable: true,
    },
    {
      accessor: 'externalId',
      title: 'External ID',
      sortable: true,
    },
  ]

  const loadCycle = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      type Response = {
        cycle: CycleDto
        school: SchoolDto
        district: DistrictDto
      }

      let response: Response = (await ApiClient.getInstance().get(`/admin/cycles/${params.cycleId}`)).data

      runInAction(() => {
        state.data = {
          cycle: response.cycle,
          school: response.school,
          district: response.district,
        }
      })
    } catch (err) {
      if (!err.response) {
        console.log(err)
      }

      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [params.cycleId, state])

  useEffect(() => {
    if (params.cycleId) {
      loadCycle().then()
    }
  }, [
    params.cycleId,
    loadCycle,
  ])

  return state.error
    ? <Alert variant="danger">{state.error}</Alert>
    : (state.loading || !state.data)
      ? <ProgressBar animated now={100}/>
      : <div>
        <div className={css(styles.header)}>
          <Breadcrumbs
            items={[
              <Link to={route(Routes.schools.list)}>Schools</Link>,
              <Link to={route(Routes.schools.show, { id: state.data.school.id })}>{state.data.school.name}</Link>,
              <Link to={route(Routes.cycles.show, { id: state.data.cycle.id })}>{state.data.cycle.name}</Link>,
              'Students',
            ]}
          />
        </div>
        <CrudResourceListView
          title="Students"
          resourceName={STUDENT_RESOURCE_NAME}
          columns={columns}
          where={[
            { id: 'cycleId', value: params.cycleId },
          ]}
          createUrl={route(Routes.students.create, { cycleId: params.cycleId })}
          showSearch={true}
          actionButtons={[
            <Button variant="secondary" onClick={showImportModal}>Import</Button>
          ]}
          tableViewCommandEventBus={state.eventBus}
        />

        {
          state.renderImportModal
            ? <ImportStudentsModal
              cycleId={Number(params.cycleId)}
              onExited={() => {
                runInAction(() => state.renderImportModal = false)
                state.eventBus.emit(TableViewEvents.REFRESH)
              }}
            />
            : null
        }
      </div>
})

type ModalProps = {
  cycleId: number
  onExited: () => void
}

const ImportStudentsModal = observer((props: ModalProps) => {
  type State = {
    show: boolean
    fileInput: React.RefObject<HTMLInputElement>
  }

  const state = useLocalObservable<State>(() => ({
    show: true,
    fileInput: React.createRef<HTMLInputElement>(),
  }))

  const submit = async (ev?: SyntheticEvent) => {
    ev?.preventDefault()

    if (state.fileInput.current && state.fileInput.current.files?.length) {
      const fileData = await fileToBase64(state.fileInput.current.files[0])

      try {
        const response = await ApiClient.getInstance().post(`/admin/cycles/${props.cycleId}/import-students`, {
          file: fileData,
        })

        try {
          getAppState().toast(`${response.data.imported} Students Imported`)
        } catch (err) {
        }
        runInAction(() => state.show = false)
      } catch (err) {
        getModalManager().showModal({
          title: 'Error',
          message: extractErrorMessage(err.response),
        })
      }
    }
  }

  return <Modal onExited={props.onExited} show={state.show}>
    <Modal.Header>
      Import Students
    </Modal.Header>
    <Modal.Body>
      <p>Choose a file to import</p>

      <Form onSubmit={submit}>
        <Form.File ref={state.fileInput}/>
      </Form>

      <hr/>

      <p>
        <a rel="noreferrer" target="_blank" href={`${process.env.PUBLIC_URL}/files/Students-Example.csv`}>Download example template</a>
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => submit()}>Submit</Button>
      <Button variant="secondary" onClick={() => runInAction(() => state.show = false)}>Cancel</Button>
    </Modal.Footer>
  </Modal>
})

const styles = StyleSheet.create({
  container: {},
  header: {
    marginBottom: 20,
  },
  cardRow: {
    marginBottom: 20,
  }
})
